import React, { useState } from "react";
import "./sidebar.css";
import user from "../../../src/assets/icon/flowbite--user-solid.svg";
import down from "../../../src/assets/icon/iconamoon--arrow-down-2-duotone.svg";
import blog from "../../../src/assets/icon/la--blog.svg";
import poetry from "../../../src/assets/icon/devicon-plain--poetry.svg";
import edit from "../../../src/assets/icon/bxs--edit.svg";
import list from "../../../src/assets/icon/solar--list-bold.svg";

import comment from "../../../src/assets/icon/mingcute--comment-fill.svg";
import phone from "../../../src/assets/icon/ri--phone-fill.svg";
const sideTabs = [
  {
    id: "01",
    name: "User List",
    icon: "user",
    dropdown: null,
  },
  {
    name: "Blogs",
    id: "02",
    icon: "blog",
    dropdown: [
      {
        id: "022",
        name: "Blog List",
        icon: list,
        dropdown: null,
      },
      {
        id: "021",
        name: "Blog Upload",
        icon: "edit",
        dropdown: null,
      },
    ],
  },
  {
    name: "Poetry",
    id: "03",
    icon: "poetry",
    dropdown: [
      {
        id: "024",
        name: "Poetry List",
        icon: list,
        dropdown: null,
      },
      {
        id: "023",
        name: "Poetry Upload",
        icon: "edit",
        dropdown: null,
      },
    ],
  },
  {
    name: "Blog Comments",
    id: "04",
    icon: comment,
    dropdown: null,
  },
  {
    name: "Poetry Comments",
    id: "05",
    icon: comment,
    dropdown: null,
  },
  {
    name: "Contact Us",
    id: "06",
    icon: phone,
    dropdown: null,
  },
];
const Sidebar = ({ onSideClick }) => {
  const [dropId, setDropId] = useState(null);
  const [openTab, setOpenTab] = useState("");
  return (
    <>
      <header className="header">
        <nav className="Sidebar-container container">
          {sideTabs.map((tab) => (
            <ul key={tab.id} className="Sidebar-nav-list">
              <div className="Side-open">
                <div
                  className="Sidebar-icon"
                  onClick={() => {
                    if (tab.id === dropId) {
                      setDropId(null);
                      onSideClick("Image");
                    } else {
                      setDropId(tab.id);
                      onSideClick(tab.name);
                    }
                    // setDropId(tab.id);
                    // setIsDropOpen(tab.id === dropId ? false : true);
                  }}
                >
                  <img src={blog} alt="" className="" />
                  <button className="Blog-list Sidebar-list">{tab.name}</button>
                  {tab.dropdown && (
                    <img src={down} alt="" className="side-dropdown-arrow" />
                  )}
                </div>
                {tab.dropdown && tab.id === dropId ? (
                  <div className="Side-dropdown">
                    {tab.dropdown.map((drop) => (
                      <div
                        className="Sidebar-icon"
                        onClick={() => onSideClick(drop.name)}
                      >
                        <img src={edit} alt="" className="" />
                        <button className="Blog-list-name sub-contain">
                          {drop.name}
                        </button>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            </ul>
          ))}
        </nav>
      </header>
    </>
  );
};

export default Sidebar;
