import React, { useState } from "react";
import "../UserList/Userlist.css";
import "./UploadPoetry.css";

import poetry from "../../../src/assets/poert1.jpg";
import poetry2 from "../../../src/assets/poert.jpg";

const ListPoetry = () => {
  return (
    <div className="container Userlist p-0">
      <div className="row">
        <div className="col-md-12">
          <div className="Userlist-heading">
            <h2>Poetry List</h2>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered poetry-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Image</th>
                  <th>Title</th>
                  <th>Descrption</th>
                  <th>Category</th>
                  <th>Writter</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    <img src={poetry} alt="Logo" className="Admin-Poetry-img" />
                  </td>
                  <td>the night everyday urban. everyday urban.</td>
                  <td>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </td>
                  <td>Sad,Happines</td>
                  <td>Sajawal</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    <img
                      src={poetry2}
                      alt="Logo"
                      className="Admin-Poetry-img"
                    />
                  </td>
                  <td>the night everyday urban.</td>
                  <td>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </td>
                  <td>Sad,Happines</td>
                  <td>Sajawal</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>
                    <img src={poetry} alt="Logo" className="Admin-Poetry-img" />
                  </td>
                  <td>the night everyday urban.</td>
                  <td>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </td>
                  <td>Sad,Happines</td>
                  <td>Sajawal</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>
                    <img
                      src={poetry2}
                      alt="Logo"
                      className="Admin-Poetry-img"
                    />
                  </td>
                  <td>the night everyday urban.</td>
                  <td>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </td>
                  <td>Sad,Happines</td>
                  <td>Sajawal</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>
                    <img src={poetry} alt="Logo" className="Admin-Poetry-img" />
                  </td>
                  <td>the night everyday urban.</td>
                  <td>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </td>
                  <td>Sad,Happines</td>
                  <td>Sajawal</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>
                    <img
                      src={poetry2}
                      alt="Logo"
                      className="Admin-Poetry-img"
                    />
                  </td>
                  <td>the night everyday urban.</td>
                  <td>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </td>
                  <td>Sad,Happines</td>
                  <td>Sajawal</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListPoetry;
