import React, { useState } from "react";
import "../BlogPage/blog.css";
import "./poetry.css";

import arroleft from "../../../src/assets/icon/iconamoon--arrow-left-2.svg";
import arrowright from "../../../src/assets/icon/iconamoon--arrow-right-2.svg";

import Poetrys from "../../Component/Poetry/Poetrys";

const Poetry = () => {
  return (
    <div className="Blog-main-sec">
      <div className="poetry-background">
        <div className="home-poetry contanier">
          <h2>Poetry Page</h2>
        </div>
      </div>
      <div className="Blog-main container">
        <h2 className="Blog-heading">Poetry</h2>
        <div className="Blog-search-main">
          <div className="Blog-Search">
            <div className="Search-container">
              <input
                type="text"
                placeholder="Search Poetrys..."
                className="search-input"
              />
              <button className="Search-btn">Search</button>
            </div>
          </div>
          <div className="Blog-btn-catager">
            <ul>
              <li>
                <a href="#" className="active">
                  All
                </a>
              </li>
              <li>
                <a href="#">Sad</a>
              </li>
              <li>
                <a href="#">Happiness</a>
              </li>
              <li>
                <a href="#">Motivation</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="Blog-show-contain container">
        <Poetrys />
        <div className="Blog-pagination">
          <ul>
            <li>
              <a href="#" className="arrow">
                <img src={arroleft} alt="Logo" />
              </a>
            </li>
            <li>
              <a href="#">1</a>
            </li>
            <li>
              <a href="#" className="active">
                2
              </a>
            </li>
            <li>
              <a href="#">3</a>
            </li>
            <li>
              <a href="#" className="arrow">
                <img src={arrowright} alt="Logo" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Poetry;
