import React, { useState } from "react";
import "./AdminHome.css";
import Sidebar from "../AdminCompnen/Sidebar/Sidebar";
import logo from "../assets/icon/logo.png";
import Userlist from "../AdminCompnen/UserList/Userlist";
import AdminContact from "../AdminCompnen/AdminContact/adminContact";
import BlogComment from "../AdminCompnen/BlogComment/blogComment";
import PoetryComment from "../AdminCompnen/PoetryComment/poetryComment";
import ListBlog from "../AdminCompnen/BlogFrom/ListBlog";
import ListPoetry from "../AdminCompnen/PoetryFrom/ListPoetry";
import BlogUploadForm from "../AdminCompnen/BlogFrom/UploadBlog";
import PoetryUploadForm from "../AdminCompnen/PoetryFrom/UploadPoetry";
const AdminHome = () => {
  const [currentTab, setCurrentTab] = useState("Image");
  return (
    <div className="container main-admin">
      <div className="row main-admin-box">
        <div className="Admin-heading col-md-12 p-0">
          <img src={logo} alt="" className="log-imaga-admin" />
        </div>
        <div className="LInk-side-bar col-md-3 p-0">
          <Sidebar onSideClick={(tab) => setCurrentTab(tab)} />
        </div>
        <div className="LInk-side-date col-md-9 p-0">
          {currentTab === "Image" && (
            <div className="mian-sec-date">
              <img src={logo} alt="" className="admin-top-logo" />
            </div>
          )}
          {currentTab === "User List" && <Userlist />}
          {currentTab === "Contact Us" && <AdminContact />}
          {currentTab === "Blog Comments" && <BlogComment />}
          {currentTab === "Poetry Comments" && <PoetryComment />}
          {(currentTab === "Blog List" || currentTab === "Blogs") && (
            <ListBlog />
          )}
          {(currentTab === "Poetry List" || currentTab === "Poetry") && (
            <ListPoetry />
          )}
          {currentTab === "Blog Upload" && <BlogUploadForm />}
          {currentTab === "Poetry Upload" && <PoetryUploadForm />}
        </div>
      </div>
    </div>
  );
};

export default AdminHome;
