import React, { useState } from "react";
import "./contact.css";
import "../../all-pages/HomePage/home.css";
import ContactFrom from "../../Component/ContactFrom/ContactFrom";
import Featureblog3 from "../../../src/assets/Contact.png";

const Contact = () => {
  return (
    <div className="contact-main-sec">
      <div className="contact-background">
        <div className="home-poetry container">
          <h2>Contact Us</h2>
        </div>
      </div>
      <div className="contact-form-main container">
        <div className="row">
          <div className="Contact-image-side col-md-5">
            <img className="Contact-img" src={Featureblog3} alt="Logo" />
          </div>
          <div className="Contact-from-side col-md-7">
            <ContactFrom />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
