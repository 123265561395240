import React, { useState } from "react";
import weritt1 from "../../../src/assets/23.webp";
import weritt2 from "../../../src/assets/images__1_-removebg-preview.png";
import weritt3 from "../../../src/assets/Habib-jalib.jpg";
import weritt11 from "../../../src/assets/11.webp";
import weritt12 from "../../../src/assets/jaun-eliya.png";
import weritt14 from "../../../src/assets/anwar.jpeg";

import weritt13 from "../../../src/assets/1601937_b_2030.jpg";
import weritt4 from "../../../src/assets/13.webp";
import weritt5 from "../../../src/assets/14.webp";
import weritt6 from "../../../src/assets/16.webp";
import weritt7 from "../../../src/assets/18.webp";
import weritt8 from "../../../src/assets/19.webp";

import "./FamousPoetry.css";
import "../Featured-blog/Featureblog.css";
import "../../all-pages/HomePage/home.css";

const FamousPoetry = () => {
  return (
    <div className="FamousPoetry-main">
      <div className="FamousPoetry-contanier container">
        <h2 className="FamousPoetry-heading">
          World Famous Poetry <div className="Feature-img">writer</div>
        </h2>
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-6 col-12">
            <div className="FamousPoetry-card">
              <img className="FamousPoetry-card-img" src={weritt4} alt="Logo" />
              <div className="FamousPoetry-card-text">Allama Iqbal</div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-12">
            <div className="FamousPoetry-card">
              <img className="FamousPoetry-card-img" src={weritt5} alt="Logo" />
              <div className="FamousPoetry-card-text">Ahmed Faraz</div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-12">
            <div className="FamousPoetry-card">
              <img className="FamousPoetry-card-img" src={weritt6} alt="Logo" />
              <div className="FamousPoetry-card-text">Amjad Islam Amjad</div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-12">
            <div className="FamousPoetry-card">
              <img className="FamousPoetry-card-img" src={weritt7} alt="Logo" />
              <div className="FamousPoetry-card-text">Wasi Shah</div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-12">
            <div className="FamousPoetry-card">
              <img className="FamousPoetry-card-img" src={weritt8} alt="Logo" />
              <div className="FamousPoetry-card-text">Parveen Shakir</div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-12">
            <div className="FamousPoetry-card">
              <img className="FamousPoetry-card-img" src={weritt1} alt="Logo" />
              <div className="FamousPoetry-card-text">Faiz Ahmed Faiz</div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-12">
            <div className="FamousPoetry-card">
              <img className="FamousPoetry-card-img" src={weritt2} alt="Logo" />
              <div className="FamousPoetry-card-text"> Munir Niazi </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-12">
            <div className="FamousPoetry-card">
              <img className="FamousPoetry-card-img" src={weritt3} alt="Logo" />
              <div className="FamousPoetry-card-text">Habib Jalib</div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-12">
            <div className="FamousPoetry-card">
              <img
                className="FamousPoetry-card-img"
                src={weritt11}
                alt="Logo"
              />
              <div className="FamousPoetry-card-text">Mirza Ghalib</div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-12">
            <div className="FamousPoetry-card">
              <img
                className="FamousPoetry-card-img"
                src={weritt12}
                alt="Logo"
              />
              <div className="FamousPoetry-card-text">Jaun Eliya</div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-12">
            <div className="FamousPoetry-card">
              <img
                className="FamousPoetry-card-img"
                src={weritt13}
                alt="Logo"
              />
              <div className="FamousPoetry-card-text">Hafeez Jalandhari</div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-12">
            <div className="FamousPoetry-card">
              <img
                className="FamousPoetry-card-img"
                src={weritt14}
                alt="Logo"
              />
              <div className="FamousPoetry-card-text">Anwar Masood</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FamousPoetry;
