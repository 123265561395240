import React, { useState } from "react";
import newsletter from "../../../src/assets/h1-img-01.png";

import "./newsletter.css";
import "../Featured-blog/Featureblog.css";
import "../../all-pages/HomePage/home.css";

const Newsletter = () => {
  return (
    <div className="FamousPoetry-main">
      <div className="Newsletter-contanier container">
        <div className="row">
          <div className="col-md-5">
            <div className="Newsletter-Sec">
              <img
                className="Newsletter-card-img"
                src={newsletter}
                alt="Logo"
              />
            </div>
          </div>
          <div className="col-md-7">
            <div className="Newsletter-card">
              <h2 className="Newsletter-heading">
                <span className="Feature-img">Mehve Muhabbat</span>A Heartfelt
                Exploration of Love, Poetry, and Stories – Connecting Souls
                Through Timeless Words and Emotional Journeys.
              </h2>
              <p className="Newsletter-subheading">
                Mehve Muhabbat, a place where the beauty of Urdu poetry comes
                alive through the power of love. Here, each poem tells a story
                of deep emotions_ whether it’s the joy of love, the pain of
                separation, or the magic of unspoken feelings.
              </p>
              <form className="Newsletter-form">
                <input
                  type="Name"
                  className="Name-input"
                  placeholder="Your Name"
                  autoComplete="Name"
                />
                <input
                  type="email"
                  className="Newsletter-input"
                  placeholder="Your Email Address"
                  autoComplete="email"
                />

                <button className="Newsletter-btn">Subscribe</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
