import React, { useState } from "react";
import logo from "../../../src/assets/icon/logo.png";
import instagram from "../../../src/assets/icon/ant-design--instagram-filled.svg";
import twitter from "../../../src/assets/icon/flowbite--twitter-solid.svg";
import youtube from "../../../src/assets/icon/flowbite--youtube-solid.svg";
import facebook from "../../../src/assets/icon/grommet-icons--facebook-option.svg";

import "./Footer.css";
import "../Featured-blog/Featureblog.css";
import "../../all-pages/HomePage/home.css";

const Footer = () => {
  return (
    <div className="footer-warrp">
      <div className="FamousPoetry-main">
        <div className="Footer-main-container container">
          <div className="Footer-logo-container">
            <img src={logo} alt="Logo" />
            <p className="Footer-header">
              <b>Mehve Muhabbat,</b> a place where the beauty of Urdu poetry
              comes alive through the power of love. Here, each poem tells a
              story of deep emotions_ whether it’s the joy of love, the pain of
              separation, or the magic of unspoken feelings. We bring you the
              finest poetry from both classic and modern poets, creating a space
              where hearts connect through words. Let the enchanting world of
              Urdu poetry fill your soul with the warmth of love.
            </p>
          </div>
          <div className="Footer-links-container">
            <ul>
              <li>
                <a href="https://www.facebook.com/share/QaFwDrMtj16kgKyC/?mibextid=qi2Omg">
                  <img src={facebook} alt="Logo" />
                </a>
              </li>
              <li>
                <a href="https://youtube.com/@mehvemuhabbat?si=_ge5cMNinV5d60kw">
                  <img src={youtube} alt="Logo" />
                </a>
              </li>

              <li>
                <a href="https://www.instagram.com/mehve_muhabbat?igsh=ZWw4YXNhZnpuMzN5">
                  <img src={instagram} alt="Logo" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        © 2024 Mehve Muhabbat, All Rights Reserved
      </div>
    </div>
  );
};

export default Footer;
