import React from "react";
import "./poetry.css"; // Create this file for styling
import hugeicons from "../../../src/assets/hugeicons--edit-01.svg";
import sideimg from "../../../src/assets/pexels-thatguycraig000-1767014.jpg";
import logo from "../../../src/assets/icon/logo.png";
import BlogPost from "../../../src/assets/poert1.jpg";
import poetry3 from "../../../src/assets/poert.jpg";
import quote from "../../../src/assets/bi--quote.svg";
import store from "../../../src/assets/icon/basil--app-store-outline.svg";
import VidoePoetry from "../../Component/VidoePoetry/VidoePoetry";
const PoetryDetail = () => {
  const poetry = {
    title: "Whispers of the Wind",
    author: "John Doe",
    category: "Nature",
    poemText: `
In Kenya’s heart, the wild untamed,
A lioness, both fierce and free.
Elsa, raised by human hands,
Yet born to roam eternity.

She leapt through rivers, danced in grass,
Her spirit wild, her heart aglow.
Between two worlds she found her way,
To be the queen of lands she’d know.

No chain could hold her, none could bind,
The call of freedom, strong and sure.
Her life a bridge from man to wild,
A lesson pure, a love secure.

Born free she was, and free she stayed,
In hearts and dreams, her story told.
Elsa, lioness of grace,
Your legacy will not grow old.
    `,
    imageUrl: BlogPost, // Placeholder image URL
    videoUrl: "https://www.example.com/sample-video.mp4",
  };

  return (
    <div className="poetry-detail-page">
      <div className="poetry-header-background container">
        <div className="poetry-main-sec-poetry row">
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="poetry-header">
              <img
                src={poetry.imageUrl}
                alt={poetry.title}
                className="poetry-image-theme"
              />
              <h1>Whispers of the Wind</h1>

              <div className="Featureblog-date">
                <div className="Featureblog-date-description">
                  <img
                    className="Featureblog-card-im"
                    src={hugeicons}
                    alt="Logo"
                  />
                  <span>Nature</span>
                </div>
                <div className="Featureblog-date-description">
                  <img
                    className="Featureblog-card-img"
                    src={hugeicons}
                    alt="Logo"
                  />
                  <span>by:John Doe</span>
                </div>
              </div>
            </div>
            <div className="poetry-body-background">
              <div className="poetry-body">
                <div className="poetry-text">
                  <pre>{poetry.poemText}</pre>
                </div>
                <div className="poetry-image-main">
                  <img src={logo} alt="" className="image-center-logo" />
                </div>
              </div>
              {/* {poetry.videoUrl && (
          <div className="poetry-video">
            <h3>Related Video:</h3>
            <div className="poetry-video-Related">
              <Player />
              <Player />
            </div>
          </div>
        )} */}
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="poetry-sidebar">
              <div className="poetry-sidebar-main">
                <h3 className="poetry-heading-side">Next Poetry:</h3>
                <div className="poetry-sidebar-list">
                  {" "}
                  <div className="Poetrys-card">
                    <div className="poetry-image">
                      <img
                        className="Poetrys-card-img"
                        src={poetry3}
                        alt="Logo"
                      />
                    </div>

                    <div className="Poetrys-card-body">
                      <div className="Poetrys-body-text">
                        <div className="poetry-Qute">
                          <img
                            className="Poetrys-card-img"
                            src={quote}
                            alt="Logo"
                          />
                        </div>
                        <h5 className="Poetrys-card-title">
                          <div className="Featureblog-date">
                            <div className="Featureblog-date-description">
                              <img
                                className="Featureblog-card-img"
                                src={store}
                                alt="Logo"
                              />
                              <span>Happiness</span>
                            </div>
                            <div className="Featureblog-date-description">
                              <img
                                className="Featureblog-card-img"
                                src={hugeicons}
                                alt="Logo"
                              />
                              <span>by Alisa Michaels </span>
                            </div>
                          </div>
                          <a href="/PoetryDetail">
                            Moving trough: the night everyday urban. Moving
                            trough: the night everyday urban Moving trough: the
                            night everyday urban Moving trough: urban..
                          </a>
                        </h5>
                        <div className="poetry-Qute poetry-Qute-ratata ">
                          <img
                            className="Poetrys-card-img"
                            src={quote}
                            alt="Logo"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="poetry-cagater">
                <h3 className="poetry-heading-side pt-4">Related Poetrys:</h3>
                <div className="poetry-cagater-list">
                  <ul>
                    <li>
                      <a href="/PoetryDetail">Whispers of the Wind</a>
                    </li>
                    <li>
                      <a href="/PoetryDetail">The Peaceful Pond</a>
                    </li>
                    <li>
                      <a href="/PoetryDetail">The River of Rain</a>
                    </li>
                    <li>
                      <a href="/PoetryDetail">The Eternal Night</a>
                    </li>
                    <li>
                      <a href="/PoetryDetail">The Garden of Flowers</a>
                    </li>
                    <li>
                      <a href="/PoetryDetail">The Sunrise</a>
                    </li>
                    <li>
                      <a href="/PoetryDetail">The Winding Path</a>
                    </li>
                    <li>
                      <a href="/PoetryDetail">The Whispering Woods</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="poetry-img-side">
                <img className="sideimg-img" src={sideimg} alt="Logo" />
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="poetry-footer">
              <VidoePoetry />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoetryDetail;
