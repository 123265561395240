import React, { useState } from "react";
import "../UserList/Userlist.css";
import "./UploadBlog.css";

import Featureblog3 from "../../../src/assets/blog-post-img-03.jpg";
import Featureblog1 from "../../../src/assets/blog-post-img-01.jpg";

const ListBlog = () => {
  return (
    <div className="container Userlist p-0">
      <div className="row">
        <div className="col-md-12">
          <div className="Userlist-heading">
            <h2>Blog List</h2>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Image</th>
                  <th>Title</th>
                  <th>Descrption</th>
                  <th>Category</th>
                  <th>Writter</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    <img
                      src={Featureblog3}
                      alt="Logo"
                      className="Admin-blog-img"
                    />
                  </td>
                  <td>the night everyday urban.</td>
                  <td>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </td>
                  <td>Travel</td>
                  <td>Sajawal</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    <img
                      src={Featureblog1}
                      alt="Logo"
                      className="Admin-blog-img"
                    />
                  </td>
                  <td>the night everyday urban.</td>
                  <td>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </td>
                  <td>Travel</td>
                  <td>Sajawal</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>
                    <img
                      src={Featureblog3}
                      alt="Logo"
                      className="Admin-blog-img"
                    />
                  </td>
                  <td>the night everyday urban.</td>
                  <td>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </td>
                  <td>Travel</td>
                  <td>Sajawal</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>
                    <img
                      src={Featureblog1}
                      alt="Logo"
                      className="Admin-blog-img"
                    />
                  </td>
                  <td>the night everyday urban.</td>
                  <td>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </td>
                  <td>Travel</td>
                  <td>Sajawal</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>
                    <img
                      src={Featureblog3}
                      alt="Logo"
                      className="Admin-blog-img"
                    />
                  </td>
                  <td>the night everyday urban.</td>
                  <td>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </td>
                  <td>Travel</td>
                  <td>Sajawal</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>
                    <img
                      src={Featureblog1}
                      alt="Logo"
                      className="Admin-blog-img"
                    />
                  </td>
                  <td>the night everyday urban.</td>
                  <td>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </td>
                  <td>Travel</td>
                  <td>Sajawal</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListBlog;
