import React, { useState } from "react";
import "./Userlist.css";
// import logo from "../assets/logo.png";
const Userlist = () => {
  return (
    <div className="container Userlist p-0">
      <div className="row">
        <div className="col-md-12">
          <div className="Userlist-heading">
            <h2>User List</h2>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>John Doe</td>
                  <td>johndoe@example.com</td>
                  <td>Active</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Jane Smith</td>
                  <td>johndoe@example.com</td>
                  <td>Active</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Jane Smith</td>
                  <td>johndoe@example.com</td>
                  <td>Active</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Jane Smith</td>
                  <td>johndoe@example.com</td>
                  <td>Active</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Userlist;
