import React from "react";
import poetry1 from "../../../src/assets/a3-dr.allama-iqbal.jpg";
import poetry2 from "../../../src/assets/faiz_112017125608.webp";
import poetry3 from "../../../src/assets/G9XpLha9QeBwuyqy5GAUGlXenr4Z5f9q2C8Q0r5R.jpeg";
import quote from "../../../src/assets/bi--quote.svg";
import store from "../../../src/assets/icon/basil--app-store-outline.svg";
import hugeicons from "../../../src/assets/icon/hugeicons--edit-01.svg";

import "../Poetry/Poetrys.css";
import "../Featured-blog/Featureblog.css";
import "../../all-pages/HomePage/home.css";
import { useNavigate } from "react-router-dom";

const poetryData = [
  {
    image: poetry1,
    category: "Patriotic & Educational",
    writer: "Allama Iqbal",
    title:
      "Lab Pe Aati Hai Dua - A Prayer for Integrity and Service to the Nation",
    description:
      "A patriotic poem that inspires integrity and service to the nation.",
    link: "/poetrydetail",
  },
  {
    image: poetry2,
    category: "Love & Romantic",
    writer: "Faiz Ahmed Faiz",
    title:
      "Dasht-e-Tanhai - The Desert of Loneliness, Longing for Love in Memories",
    description: "A romantic poem exploring the themes of loneliness and love.",
    link: "/poetrydetail",
  },
  {
    image: poetry3,
    category: "Grief & Separation",
    writer: "Naseer Turabi",
    title:
      "Woh Humsafar Tha - A Poetic Journey of Love, Separation, and the Pain of Distance",
    description: "A poignant poem about separation and the pain it brings.",
    link: "/poetrydetail",
  },
];

const Poetrys = () => {
  const navigate = useNavigate();
  return (
    <div className="Featureblog-main">
      <div className="Poetrys-contanier container">
        <div className="Blogs-main-content">
          <h2 className="Featureblog-heading">
            Famous<div className="Feature-img">Poetry</div>
          </h2>
          <button className="Blogs-see-all" onClick={() => navigate("/poetry")}>
            See All
          </button>
        </div>
        <div className="row">
          {poetryData.map((poem, index) => (
            <div key={index} className="col-lg-4 col-md-6 col-sm-12 pb-4">
              <div className="Poetrys-card">
                <div className="poetry-image">
                  <img
                    className="Poetrys-card-img"
                    src={poem.image}
                    alt={poem.title}
                  />
                </div>
                <div className="Poetrys-card-body">
                  <div className="Poetrys-body-text">
                    <div className="poetry-Qute">
                      <img
                        className="Poetrys-card-img"
                        src={quote}
                        alt="Quote"
                      />
                    </div>
                    <h5 className="Poetrys-card-title">
                      <div className="Featureblog-date">
                        <div className="Featureblog-date-description">
                          <img
                            className="Featureblog-card-img"
                            src={store}
                            alt="Category"
                          />
                          <span>{poem.category}</span>
                        </div>
                        <div className="Featureblog-date-description">
                          <img
                            className="Featureblog-card-img"
                            src={hugeicons}
                            alt="Writer"
                          />
                          <span>by {poem.writer}</span>
                        </div>
                      </div>
                      <a onClick={() => navigate(poem.link)}>
                        {poem.title.length > 50
                          ? poem.title.substring(0, 50) + "..."
                          : poem.title}
                      </a>
                    </h5>
                    <div className="poetry-Qute poetry-Qute-ratata">
                      <img
                        className="Poetrys-card-img"
                        src={quote}
                        alt="Quote"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Poetrys;
