import React, { useState } from "react";
import Featureblog1 from "../../../src/assets/blog-post-img-01.jpg";
import Featureblog2 from "../../../src/assets/blog-post-img-02.jpg";
import calender from "../../../src/assets/guidance--calendar.svg";
import hugeicons from "../../../src/assets/hugeicons--edit-01.svg";
import { NavLink, useNavigate } from "react-router-dom";
import "./Featureblog.css";
import "../../all-pages/HomePage/home.css";
// const Latesblog = [
//   {
//     title: `Top Entertainment Cities: Where to Experience the Best of
//             Culture and Nightlife`,
//     description: `For those who thrive on excitement, this blog will take
//                   readers through some of the world's top entertainment cities.
//                   From the lively streets of New Orleans during Mardi Gras to
//                   the non-stop energy of Las Vegas, we'll explore destinations
//                   known for their music, theater, festivals, and nightlife.
//                   These cities offer a mix of high-energy performances, cultural
//                   events, and experiences that make for unforgettable vacations.`,
//     date: "July 22, 2024",
//     author: "Sana Khan",
//     image: Featureblog1,
//   },
//   {
//     title: `Luxury Travel Experiences: The Most Opulent Destinations and
//                     Resorts`,
//     description: `This blog will provide readers with 10 must-visit destinations for travelers with a budget. From the picturesque coastal cities of California to the bustling metropolises of New York, we'll explore destinations known for their unique charm, vibrant culture, and affordable accommodations. These cities offer a mix of luxury and convenience that make for unforgettable vacations.`,
//     date: "July 12, 2024",
//     author: "Sara Johnson",
//     image: Featureblog2,
//   },
// ];
const Featureblog = () => {
  const navigate = useNavigate();
  return (
    <div className="Featureblog-main">
      <div className="Featureblog-contanier container">
        <h2 className="Featureblog-heading  Featureblog-heading-main-b">
          Lates blog <div className="Feature-img">posts</div>
        </h2>
        <div className="Feature">
          <div className="row">
            <div className="col-md-6 col-lg-6 col-12 ">
              <div className="Featureblog-card">
                <img
                  className="Featureblog-card-img"
                  src={Featureblog1}
                  alt="Logo"
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-12 mainto">
              <div className="Featureblog-card-body">
                <div className="Featureblog-body-text">
                  <div className="Featureblog-date">
                    <div className="Featureblog-date-description">
                      <img
                        className="Featureblog-card-img"
                        src={calender}
                        alt="Logo"
                      />
                      <span>July 22, 2024</span>
                    </div>
                    <div className="Featureblog-date-description">
                      <img
                        className="Featureblog-card-img"
                        src={hugeicons}
                        alt="Logo"
                      />
                      <span>by Sana Khan </span>
                    </div>
                  </div>
                  <h5 className="Featureblog-card-title">
                    <a role="button" onClick={() => navigate("/blogdetail")}>
                      Top Entertainment Cities: Where to Experience the Best of
                      Culture and Nightlife
                    </a>
                  </h5>
                  <p className="Featureblog-card-text">
                    For those who thrive on excitement, this blog will take
                    readers through some of the world’s top entertainment
                    cities. From the lively streets of New Orleans during Mardi
                    Gras to the non-stop energy of Las Vegas,
                  </p>
                  <div className="Featureblog-card-footer">
                    <button className="text-button">Read More...</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main-fathe row">
            <div className="col-md-6 col-lg-6 col-12 pt-4">
              <div className="Featureblog-card-body">
                <div className="Featureblog-body-text">
                  <div className="Featureblog-date">
                    <div className="Featureblog-date-description">
                      <img
                        className="Featureblog-card-img"
                        src={calender}
                        alt="Logo"
                      />
                      <span>May 12, 2024</span>
                    </div>
                    <div className="Featureblog-date-description">
                      <img
                        className="Featureblog-card-img"
                        src={hugeicons}
                        alt="Logo"
                      />
                      <span>by Ali Amad </span>
                    </div>
                  </div>
                  <h5 className="Featureblog-card-title">
                    <a role="button" onClick={() => navigate("/blogdetail")}>
                      Luxury Travel Experiences: The Most Opulent Destinations
                      and Resorts
                    </a>
                  </h5>
                  <p className="Featureblog-card-text">
                    For those seeking indulgence, this blog will take readers on
                    a journey through the most opulent travel experiences
                    available. From five-star resorts with private beaches to
                    exclusive safaris in Africa,
                  </p>
                  <div className="Featureblog-card-footer">
                    <button className="text-button">Read More...</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-12 pt-4">
              <div className="Featureblog-card">
                <img
                  className="Featureblog-card-img"
                  src={Featureblog2}
                  alt="Logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Featureblog;
