import React, { useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "../src/Component/navbar/navbar";
import Home from "../src/all-pages/HomePage/home";
import Footer from "./Component/Footer/Footer";
import Contact from "./all-pages/ContactUs/contact";
import Blog from "./all-pages/BlogPage/blog";
import Poetry from "./all-pages/PoetryPage/poetry";
import BlogDetail from "./all-pages/BlogPage/blogdetail";
import PoetryDetail from "./all-pages/PoetryPage/poetrydetail";
import LoginFrom from "./all-pages/LoginPage/Login";
import AdminHome from "./AdminPage/AdminHome";
import VideoPage from "./all-pages/VidoePage/VidoePage";
// import News from "./pages/News";

const App = () => {
  const [modalShow, setShowModal] = useState(false);
  return (
    <Router>
      <Navbar onModalShow={() => setShowModal(true)} />
      <>
        <LoginFrom show={modalShow} onHide={() => setShowModal(false)} />
        <main className="app-main">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Login" element={<LoginFrom />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/poetry" element={<Poetry />} />
            <Route path="/blogdetail" element={<BlogDetail />} />
            <Route path="/poetrydetail" element={<PoetryDetail />} />
            <Route path="/VidoePage" element={<VideoPage />} />

            <Route path="/AdminHome" element={<AdminHome />} />
          </Routes>
        </main>
      </>
      <Footer />
    </Router>
  );
};

export default App;
