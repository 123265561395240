import React, { useState } from "react";
import "./home.css";
import Featureblog from "../../Component/Featured-blog/Featureblog";
import FamousPoetry from "../../Component/FamousPoetry/FamousPoetry";
import Blogs from "../../Component/Blogs/Blogs";
import Newsletter from "../../Component/Newsletter/newsletter";
import Poetrys from "../../Component/Poetry/Poetrys";
import VidoePoetry from "../../Component/VidoePoetry/VidoePoetry";
import AboutUs from "../../Component/AboutUs/aboutUs";

const Home = () => {
  return (
    <div className="home-main-sec">
      <div className="home-background">
        <div className="home-poetry contanier">
          <h2 className="">
            "One Day you will mis me like I miss you. But believe me you will be
            too late"
          </h2>
        </div>
      </div>

      <Featureblog />
      <FamousPoetry />
      <Blogs />
      <Newsletter />
      <Poetrys />
      <AboutUs />
      <VidoePoetry />
    </div>
  );
};

export default Home;
