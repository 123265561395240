import React, { useState } from "react";
import "../BlogPage/blog.css";
import "../HomePage/home.css";
import BlogPost from "../../../src/assets/blog-post-img-01.jpg";
import calender from "../../../src/assets/guidance--calendar.svg";
import Featureblog3 from "../../../src/assets/blog-post-img-03.jpg";
import hugeicons from "../../../src/assets/hugeicons--edit-01.svg";
import store from "../../../src/assets/icon/basil--app-store-outline.svg";
import InstagramImg from "../../../src/assets/a31f8c6959edffdcc82654e04213ea66.jpg";
import InstagramImg1 from "../../../src/assets/Ourfamilypassport-Family-Travel-Blog-Italy.jpg";

import Instagram from "../../../src/assets/pexels-rdne-6657524.jpg";
import Instagram1 from "../../../src/assets/pexels-rdne-6708945.jpg";
import Instagram2 from "../../../src/assets/pexels-suzyhazelwood-1526049.jpg";
import Instagram3 from "../../../src/assets/blog-post-img-08-1.jpg";
import Instagram4 from "../../../src/assets/HOME.jpg";
import Instagram5 from "../../../src/assets/360_F_552209893_vXBU90Mj9zxsPV6xWHMG1giaKms7odKo.jpg";

const BlogDetail = () => {
  return (
    <div className="Blog-main-sec">
      <div className="Blogdetail-background">
        <div className="home-poetry contanier">
          <h2>Blogs Detail Page</h2>
        </div>
      </div>
      <div className="Blogdetail container">
        <div className="Blogdetail-img">
          <img src={BlogPost} alt="Blog Post" />
        </div>
        <div className="Blogdetail-content row">
          <div className="Blogdetail-post col-lg-8 col-md-7 col-sm-12">
            <div className="Featureblog-card-body">
              <div className="Featureblog-body-text">
                <div className="Featureblog-date">
                  <div className="Featureblog-date-description">
                    <img
                      className="Featureblog-card-img"
                      src={calender}
                      alt="Logo"
                    />
                    <span>July 12, 2021</span>
                  </div>
                  <div className="Featureblog-date-description">
                    <img
                      className="Featureblog-card-im"
                      src={hugeicons}
                      alt="Logo"
                    />
                    <span>Travel</span>
                  </div>
                  <div className="Featureblog-date-description">
                    <img
                      className="Featureblog-card-img"
                      src={hugeicons}
                      alt="Logo"
                    />
                    <span>by Alisa Michaels </span>
                  </div>
                </div>
                <h5 className="Featureblog-card-title">
                  <a href="#">
                    The Art of Relaxation: Best Travel Spots for a Peaceful
                    Retreat
                  </a>
                </h5>
                <p className="Featureblog-card-text">
                  In a world that never seems to slow down, finding a moment of
                  tranquility can feel like a luxury. For those seeking respite
                  from the daily grind, a peaceful travel retreat can offer the
                  perfect escape. Whether you’re drawn to serene beaches,
                  tranquil gardens, or scenic landscapes, the right destination
                  can provide the relaxation you crave. Here are some of the
                  best travel spots for a rejuvenating retreat.
                </p>
                <div className="Blogdetail-Second-contain">
                  <h5 className="Featureblog-card-title">
                    <a href="#">Bali, Indonesia: A Sanctuary of Serenity</a>
                  </h5>
                  <div className="Blogdetail-text-second">
                    <img src={Instagram3} alt="Blog Instagram" />
                    <p className="Featureblog-card-text">
                      Bali, often dubbed the “Island of the Gods,” is a haven
                      for those seeking peace and tranquility. With its lush
                      rice terraces, pristine beaches, and spiritual temples,
                      Bali offers an idyllic setting for relaxation. Highlights:
                      Ubud: Known for its vibrant arts scene and tranquil rice
                      paddies. Ideal for yoga retreats and meditation. Seminyak:
                      A hub for luxury resorts and spa treatments with beautiful
                      beachfront views. Must-Do Activities: Participate in a
                      sunrise yoga session overlooking the rice terraces.
                      Indulge in a traditional Balinese massage at a local spa.
                      Visit sacred temples like Uluwatu and Tanah Lot for a dose
                      of spiritual peace. 2. Kyoto, Japan: Zen and Serenity
                      Kyoto, the ancient capital of Japan, is a city steeped in
                      tradition and tranquility. Its historic temples, serene
                      gardens, and traditional tea houses make it a perfect
                      destination for those seeking inner peace. Highlights:
                      Kinkaku-ji (Golden Pavilion): A stunning temple surrounded
                      by beautifully manicured gardens. Arashiyama Bamboo Grove:
                      A peaceful walk through towering bamboo stalks. Must-Do
                      Activities: Experience a traditional tea ceremony in a
                      centuries-old tea house. Take a meditative stroll through
                      the Zen gardens of Ryoan-ji. Visit the tranquil Fushimi
                      Inari Shrine with its thousands of red torii gates. 3.
                      Santorini, Greece: A Scenic Escape Santorini, with its
                      breathtaking sunsets and stunning white-washed
                      architecture, is the epitome of relaxation. This Aegean
                      paradise offers a blend of natural beauty and luxurious
                      comfort. Highlights: Oia: Famous for its iconic sunsets
                      and charming blue-domed churches. Fira: The island’s
                      capital with upscale dining and shopping options. Must-Do
                      Activities: Watch the sunset from a cliffside restaurant
                      in Oia. Enjoy a leisurely swim in the crystal-clear waters
                      of the Caldera. Explore the charming streets of Fira and
                      indulge in local Greek cuisine. 4. Tuscany, Italy: Rolling
                      Hills and Rustic Charm Tuscany, known for its picturesque
                      landscapes and historic towns, is the perfect destination
                      for a relaxing countryside retreat. The region’s rolling
                      hills and vineyards offer a peaceful escape from the
                      hustle and bustle of city life. Highlights: Chianti
                      Region: Renowned for its vineyards and charming wineries.
                      Florence: A historic city offering a blend of culture and
                      relaxation. Must-Do Activities: Savor a wine tasting tour
                      in the Chianti region. Explore the medieval towns of Siena
                      and San Gimignano. Enjoy a leisurely meal at a countryside
                      trattoria, savoring local Tuscan dishes. 5. Queenstown,
                      New Zealand: Nature’s Sanctuary Queenstown, nestled on the
                      shores of Lake Wakatipu and surrounded by the Southern
                      Alps, is a paradise for nature lovers seeking a peaceful
                      retreat. Its stunning landscapes provide a serene backdrop
                      for relaxation. Highlights: Lake Wakatipu: A picturesque
                      lake offering serene views and relaxing boat cruises.
                      Remarkables: A mountain range ideal for gentle hikes and
                      breathtaking vistas. Must-Do Activities: Take a scenic
                      cruise on Lake Wakatipu to enjoy the tranquil waters and
                      majestic mountains. Explore the nearby Fiordland National
                      Park on a leisurely hike. Relax at a lakeside café,
                      soaking in the natural beauty of Queenstown.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="Blogdetail-post-comment">
              <h4>Comments</h4>
              <div className="comments-list">
                <form className="form">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      placeholder="Your Comment"
                    ></textarea>
                  </div>
                  <div className="form-group-main row">
                    <div className="form-group col-md-6 col-sm-12 mt-4">
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Your Name"
                      />
                    </div>
                    <div className="form-group col-md-6 col-sm-12 mt-4">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Your Email"
                      />
                    </div>
                    <div className="form-group col-md-6 col-sm-12 mt-4">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="exampleCheck1"
                        />
                        <label className="form-check-label" for="exampleCheck1">
                          Save my name, email, and blog post I comment.
                        </label>
                      </div>
                    </div>
                  </div>
                  <button type="submit" className="Blogdetail-btn">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="Blogdetail-sidebar col-lg-4 col-md-5 col-sm-12">
            <div className="Blogs-card">
              <div className="Blog-catagorter">
                <img className="Blogs-card-img" src={Featureblog3} alt="Logo" />
                <div className="blog-catagorter-itam">
                  <img className="Blogs-store" src={store} alt="Logo" />
                  <span>Travel</span>
                </div>
              </div>
              <div className="Featureblog-card-body">
                <div className="Featureblog-body-text">
                  <div className="Featureblog-date">
                    <div className="Featureblog-date-description">
                      <img
                        className="Featureblog-card-img"
                        src={calender}
                        alt="Logo"
                      />
                      <span>July 12, 2021</span>
                    </div>
                    <div className="Featureblog-date-description">
                      <img
                        className="Featureblog-card-img"
                        src={hugeicons}
                        alt="Logo"
                      />
                      <span>by Alisa Michaels </span>
                    </div>
                  </div>
                  <h5 className="Featureblog-card-title">
                    <a href="/BlogDetail">
                      Discovering Hidden Gems: Off-the-Beaten-Path Travel
                      Destinations
                    </a>
                  </h5>
                  {/* <div className="Featureblog-card-footer">
                    <button className="text-button">Read More...</button>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="Blogdetail-Categories">
              <h4>Categories</h4>
              <div className="Blogdetail-categories-list">
                <ul>
                  <li>
                    <a href="#">Travel</a>
                  </li>
                  <li>
                    <a href="#">Technology</a>
                  </li>
                  <li>
                    <a href="#">Art</a>
                  </li>
                  <li>
                    <a href="#">Sports</a>
                  </li>
                  <li>
                    <a href="#">Lifestyle</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="Blogdetail-Instagram-main">
              <h4>Instagram</h4>
              <div className="Blogdetail-Instagram row">
                <div className="Blogdetail-Instagram-item col-lg-4 col-md-6 col-sm-12 mt-2">
                  <img src={Instagram} alt="Blog Instagram" />
                </div>
                <div className="Blogdetail-Instagram-item col-lg-4 col-md-6 col-sm-12 mt-2">
                  <img src={Instagram1} alt="Blog Instagram" />
                </div>
                <div className="Blogdetail-Instagram-item col-lg-4 col-md-6 col-sm-12 mt-2">
                  <img src={Instagram3} alt="Blog Instagram" />
                </div>
                <div className="Blogdetail-Instagram-item col-lg-4 col-md-6 col-sm-12 mt-2">
                  <img src={Instagram4} alt="Blog Instagram" />
                </div>
                <div className="Blogdetail-Instagram-item col-lg-4 col-md-6 col-sm-12 mt-2">
                  <img src={Instagram5} alt="Blog Instagram" />
                </div>
                <div className="Blogdetail-Instagram-item col-lg-4 col-md-6 col-sm-12 mt-2">
                  <img src={Instagram2} alt="Blog Instagram" />
                </div>
              </div>
            </div>
            <div className="Blogdetail-newsletter">
              <h4>Newsletter</h4>
              <form action="#">
                <input
                  type="text"
                  placeholder="Your Name"
                  className="form-control mb-4"
                />
                <input
                  type="text"
                  placeholder="Enter your email"
                  className="form-control"
                />

                <button type="submit" className="Blogdetail-btn">
                  Subscribe
                </button>
              </form>
            </div>
            <div className="Blogdetail-Instagram-img">
              <img src={InstagramImg} alt="Blog Instagram" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
