import React, { useState } from "react";
import Featureblog3 from "../../../src/assets/blog-post-img-03.jpg";
import Featureblog4 from "../../../src/assets/blog-post-img-04.jpg";
import Featureblog5 from "../../../src/assets/blog-post-img-05.jpg";
import calender from "../../../src/assets/guidance--calendar.svg";
import hugeicons from "../../../src/assets/hugeicons--edit-01.svg";
import store from "../../../src/assets/icon/basil--app-store-outline.svg";

import "../Blogs/blogs.css";
import "../Featured-blog/Featureblog.css";
import "../../all-pages/HomePage/home.css";
import { useNavigate } from "react-router-dom";

const Blogs = () => {
  const navigate = useNavigate();
  const blog = [
    {
      image: Featureblog3,
      category: "Adventure Travel",
      writer: "by Sajawal Chohan ",
      date: "July 12, 2021",
      title: "Discovering Hidden Gems: Off-the-Beaten-Path Travel Destinations",
    },
    {
      image: Featureblog4,
      category: "Wellness & Relaxation",
      writer: "by Sameer Ali",
      date: "July 12, 2020",
      title: " The Art of Relaxation: Best Travel Spots for a Peaceful Retreat",
    },
    {
      image: Featureblog5,
      category: "Cultural & Event Travel",
      writer: "by Mehboob Alam",
      date: "July 12, 2024",
      title:
        "  Ultimate Guide to Festival Travel: Music, Food, and Cultural Festivals Around the World",
    },
  ];
  return (
    <div className="Featureblog-main">
      <div className="Blogs-contanier container">
        <div className="Blogs-main-content">
          <h2 className="Featureblog-heading-posts Featureblog-heading ">
            Feature blog <div className="Feature-img">posts</div>
          </h2>
          <button className="Blogs-see-all" onClick={() => navigate("/blog")}>
            See All
          </button>
        </div>
        <div className="row">
          {blog.map((poetry, index) => (
            <div key={index} className="col-lg-4 col-md-6 col-sm-12 pb-4">
              <div className="Blogs-card">
                <div className="Blog-catagorter">
                  <img
                    className="Blogs-card-img"
                    src={poetry.image}
                    alt={poetry.title}
                  />
                  <div className="blog-catagorter-itam">
                    <img className="Blogs-store" src={store} alt="Logo" />
                    <span>{poetry.category}</span>
                  </div>
                </div>
                <div className="Featureblog-card-body">
                  <div className="Featureblog-body-text">
                    <div className="Featureblog-date">
                      <div className="Featureblog-date-description">
                        <img
                          className="Featureblog-card-img"
                          src={calender}
                          alt="Logo"
                        />
                        <span>{poetry.date}</span>
                      </div>
                      <div className="Featureblog-date-description">
                        <img
                          className="Featureblog-card-img"
                          src={hugeicons}
                          alt="Logo"
                        />
                        <span>{poetry.writer}</span>
                      </div>
                    </div>
                    <h5 className="Featureblog-card-title">
                      <a onClick={() => navigate("/blogdetail")}>
                        {poetry.title.length > 50
                          ? poetry.title.substring(0, 50) + "..."
                          : poetry.title}
                      </a>
                    </h5>
                    {/* <div className="Featureblog-card-footer">
                    <button className="text-button">Read More...</button>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blogs;
