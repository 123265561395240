import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import poertryimg1 from "../../../src/assets/456085870_529768449393727_3446274341318510777_n.jpg";
import poertryimg2 from "../../../src/assets/457024785_536834298687142_2817801441044765091_n.jpg";
import poertryimg3 from "../../../src/assets/457045591_534317585605480_1319778905190270548_n.jpg";
import poertryimg4 from "../../../src/assets/457102287_536366345400604_269354560379633355_n.jpg";
import poertryimg5 from "../../../src/assets/457154662_536428828727689_6266943503559101377_n.jpg";
import poertryimg6 from "../../../src/assets/457404922_538218211882084_3664042089367731056_n.jpg";
import poertryimg7 from "../../../src/assets/457739654_538829001821005_7888470535175015588_n.jpg";
import poertryimg8 from "../../../src/assets/458276290_541490241554881_6315464591314738975_n.jpg";
import playicon from "../../../src/assets/icon/bi--play-circle.svg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./VidoePoetry.css";
import "../Featured-blog/Featureblog.css";
import "../FamousPoetry/FamousPoetry.css";

import "../../all-pages/HomePage/home.css";

// import required modules
import { Navigation, Autoplay } from "swiper/modules";

const VidoePoetry = () => {
  return (
    <>
      <div className="VidoePoetry-main">
        <div className="VidoePoetry-contanier container">
          <h2 className="FamousPoetry-heading">
            Poetry<div className="Feature-img">Vidoe</div>
          </h2>
          <Swiper
            slidesPerView={4}
            spaceBetween={30}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              // when window width is >= 640px (sm screen)
              375: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              576: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              // when window width is >= 640px (sm screen)
              640: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              // when window width is >= 768px (md screen)
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              // when window width is >= 1024px (large screen)
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              // when window width is >= 1024px (large screen)
              1440: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
            onAutoplay={(slide) => console.log(slide)}
            pagination={{
              clickable: true,
            }}
            // navigation={true}
            modules={[Navigation, Autoplay]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="VidoePoetry-item">
                <img className="poertryimg-img" src={poertryimg1} alt="Logo" />

                <div className="VidoePoetry-item-text">
                  Emotional, personal poems.
                </div>

                <a href="/VidoePage" className="VidoePoetry-item-btn">
                  <img className="playicon-img" src={playicon} alt="Logo" />
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="VidoePoetry-item">
                <img className="poertryimg-img" src={poertryimg2} alt="Logo" />

                <div className="VidoePoetry-item-text">Storytelling poems.</div>
                <a href="/VidoePage" className="VidoePoetry-item-btn">
                  <img className="playicon-img" src={playicon} alt="Logo" />
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="VidoePoetry-item">
                <img className="poertryimg-img" src={poertryimg3} alt="Logo" />

                <div className="VidoePoetry-item-text">Long, heroic tales.</div>
                <a href="/VidoePage" className="VidoePoetry-item-btn">
                  <img className="playicon-img" src={playicon} alt="Logo" />
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="VidoePoetry-item">
                <img className="poertryimg-img" src={poertryimg4} alt="Logo" />

                <div className="VidoePoetry-item-text">
                  Short, nature-focused poems.
                </div>
                <a href="/VidoePage" className="VidoePoetry-item-btn">
                  <img className="playicon-img" src={playicon} alt="Logo" />
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="VidoePoetry-item">
                <img className="poertryimg-img" src={poertryimg5} alt="Logo" />

                <div className="VidoePoetry-item-text">
                  14-line structured poems.
                </div>
                <a href="/VidoePage" className="VidoePoetry-item-btn">
                  <img className="playicon-img" src={playicon} alt="Logo" />
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="VidoePoetry-item">
                <img className="poertryimg-img" src={poertryimg6} alt="Logo" />

                <div className="VidoePoetry-item-text">
                  Poems without rules.
                </div>
                <a href="/VidoePage" className="VidoePoetry-item-btn">
                  <img className="playicon-img" src={playicon} alt="Logo" />
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="VidoePoetry-item">
                <img className="poertryimg-img" src={poertryimg7} alt="Logo" />

                <div className="VidoePoetry-item-text">
                  Praise-focused poems
                </div>
                <a href="/VidoePage" className="VidoePoetry-item-btn">
                  <img className="playicon-img" src={playicon} alt="Logo" />
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="VidoePoetry-item">
                <img className="poertryimg-img" src={poertryimg8} alt="Logo" />

                <div className="VidoePoetry-item-text">
                  Song-like, rhythmic poems
                </div>
                <a href="/VidoePage" className="VidoePoetry-item-btn">
                  <img className="playicon-img" src={playicon} alt="Logo" />
                </a>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
};
export default VidoePoetry;
