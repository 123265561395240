import React, { useState } from "react";
import "./ContactFrom.css";

const ContactFrom = () => {
  return (
    <div className="ContactFrom-main-sec">
      <div className="ContactFrom-Sec container">
        <h2>Contact Us</h2>
        <form className="row contact-warp">
          <div className="form-group col-md-6">
            <label htmlFor="name">Name</label>
            <input type="text" className="form-control" id="name" required />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="email">Email</label>
            <input type="email" className="form-control" id="email" required />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="number">Phone Number</label>
            <input
              type="number"
              className="form-control"
              id="number"
              required
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="subject">Subject</label>
            <input type="text" className="form-control" id="subject" required />
          </div>
          <div className="form-group col-md-12">
            <label htmlFor="message">Message</label>
            <textarea
              className="form-control"
              id="message"
              rows="3"
              required
            ></textarea>
          </div>
          <div className="form-group-btn">
            <button type="submit" className="btn-submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactFrom;
