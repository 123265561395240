import React, { useState } from "react";
import instagram from "../../../src/assets/icon/ant-design--instagram-filled.svg";
import twitter from "../../../src/assets/icon/flowbite--twitter-solid.svg";
import youtube from "../../../src/assets/icon/flowbite--youtube-solid.svg";
import facebook from "../../../src/assets/icon/grommet-icons--facebook-option.svg";
import close from "../../../src/assets/icon/eva--close-fill.svg";

import "./Login.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const LoginFrom = ({ show, onHide }) => {
  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modal-content">
        <div className="LoginFrom-Sec container">
          <form className="row LoginFrom-warp">
            <h2>Login</h2>
            <div className="form-group col-md-12">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                required
                placeholder="Email"
              />
            </div>
            <div className="form-group col-md-12">
              <label htmlFor="number">Password</label>
              <input
                type="password"
                className="form-control"
                id="number"
                required
                placeholder="Password"
              />
            </div>
            <div className="LoginFrom-btn col-md-12">
              <button type="submit" className="w-100">
                Submit
              </button>
            </div>

            <div className="LoginFrom-icon">
              <div className="col-md-12 col-sm-12">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/share/QaFwDrMtj16kgKyC/?mibextid=qi2Omg">
                      <img src={facebook} alt="Logo" />
                    </a>
                  </li>
                  <li>
                    <a href="https://youtube.com/@mehvemuhabbat?si=_ge5cMNinV5d60kw">
                      <img src={youtube} alt="Logo" />
                    </a>
                  </li>

                  <li>
                    <a href="https://www.instagram.com/mehve_muhabbat?igsh=ZWw4YXNhZnpuMzN5">
                      <img src={instagram} alt="Logo" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </form>
          <div className="">
            <Button className="LoginFrom-close" onClick={onHide}>
              <img src={close} alt="Close" />
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LoginFrom;
