import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../../src/assets/icon/logo.png";
import "./navbar.css";
import "../../all-pages/HomePage/home.css";

const Navbar = ({ onModalShow }) => {
  return (
    <>
      <header className="header main-header-poetry">
        <nav className="nav-container container">
          <NavLink to="/" className="nav-logo">
            <img src={logo} alt="" className="" />
          </NavLink>
          <ul className="nav-list">
            <li className="nav-item">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? "nav-link active" : "nav-link"
                }
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/Blog"
                className={({ isActive }) =>
                  isActive ? "nav-link active" : "nav-link"
                }
              >
                Blog
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/Poetry"
                className={({ isActive }) =>
                  isActive ? "nav-link active" : "nav-link"
                }
              >
                Portey
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/Contact"
                className={({ isActive }) =>
                  isActive ? "nav-link active" : "nav-link"
                }
              >
                Contact Us
              </NavLink>
            </li>
            <li className="nav-item">
              <button className="nav-btn" onClick={onModalShow} type="button">
                Login
              </button>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
// import React, { useState } from "react";
// import { NavLink, useLocation, useParams } from "react-router-dom";
// import logo from "../../../src/assets/icon/logo.png";
// import "./navbar.css";
// import "../../all-pages/HomePage/home.css";

// const Navbar = ({ onModalShow }) => {
//   const route = useLocation();
//   console.log("route", route);

//   return (
//     <>
//       <header className="header main-header-poetry">
//         <nav className="nav-container container">
//           <NavLink to="/" className="nav-logo">
//             <img src={logo} alt="" className="" />
//           </NavLink>
//           <ul className="nav-list">
//             <li className="nav-item">
//               <NavLink
//                 to="/"
//                 className={`nav-link ${route.pathname === "/" ? "active" : ""}`}
//               >
//                 Home
//               </NavLink>
//             </li>
//             <li className="nav-item">
//               <NavLink
//                 to="/Blog"
//                 className={`nav-link ${
//                   route.pathname === "/Blog" ? "active" : ""
//                 }`}
//               >
//                 Blog
//               </NavLink>
//             </li>
//             <li className="nav-item">
//               <NavLink
//                 to="/Poetry"
//                 className={`nav-link ${
//                   route.pathname === "/Poetry" ? "active" : ""
//                 }`}
//               >
//                 Portey
//               </NavLink>
//             </li>
//             <li className="nav-item">
//               <NavLink
//                 to="/Contact"
//                 className={`nav-link ${
//                   route.pathname === "/Contact" ? "active" : ""
//                 }`}
//               >
//                 Contact Us
//               </NavLink>
//             </li>
//             <li className="nav-item">
//               <button className="nav-btn" onClick={onModalShow} type="button">
//                 Login
//               </button>
//             </li>
//           </ul>
//         </nav>
//       </header>
//     </>
//   );
// };

// export default Navbar;
