import React, { useState } from "react";
import "../VidoePage/VidoePage.css"; // Create this file for styling
import poertryimg1 from "../../../src/assets/456085870_529768449393727_3446274341318510777_n.jpg";
import poertryimg2 from "../../../src/assets/457024785_536834298687142_2817801441044765091_n.jpg";
import poertryimg3 from "../../../src/assets/457045591_534317585605480_1319778905190270548_n.jpg";
import poertryimg4 from "../../../src/assets/457102287_536366345400604_269354560379633355_n.jpg";
import poertryimg5 from "../../../src/assets/457154662_536428828727689_6266943503559101377_n.jpg";
import poertryimg6 from "../../../src/assets/457404922_538218211882084_3664042089367731056_n.jpg";
import poertryimg7 from "../../../src/assets/457739654_538829001821005_7888470535175015588_n.jpg";
import poertryimg8 from "../../../src/assets/458276290_541490241554881_6315464591314738975_n.jpg";
import playicon from "../../../src/assets/icon/bi--play-circle.svg";
// import Vimeo from "@vimeo/player";
// import ReactPlayer from "react-player";

const VideoPage = () => {
  return (
    <div className="VideoPage-main-sec">
      <div className="VideoPage-background">
        <div className="VideoPage container">
          <h2 className="VideoPage-title">
            "One Day you will mis me like I miss you. But believe me you will be
            too late"
          </h2>
          <div className="VideoPage-video row">
            <div className="VideoPage-video-col col-md-12">
              <iframe
                width="100%"
                height="500px"
                src="https://www.youtube.com/embed/UuKFPRBem-k"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="VideoPage-list">
            <h4>Related Video</h4>
            <div className="VideoPage-list-sec">
              <div className="VidoePoetry-item">
                <img className="poertryimg-img" src={poertryimg1} alt="Logo" />

                <div className="VidoePoetry-item-text">
                  Emotional, personal poems.
                </div>

                <a href="/VidoePage" className="VidoePoetry-item-btn">
                  <img className="playicon-img" src={playicon} alt="Logo" />
                </a>
              </div>
              <div className="VidoePoetry-item">
                <img className="poertryimg-img" src={poertryimg2} alt="Logo" />

                <div className="VidoePoetry-item-text">Storytelling poems.</div>
                <a href="/VidoePage" className="VidoePoetry-item-btn">
                  <img className="playicon-img" src={playicon} alt="Logo" />
                </a>
              </div>
              <div className="VidoePoetry-item">
                <img className="poertryimg-img" src={poertryimg3} alt="Logo" />

                <div className="VidoePoetry-item-text">Long, heroic tales.</div>
                <a href="/VidoePage" className="VidoePoetry-item-btn">
                  <img className="playicon-img" src={playicon} alt="Logo" />
                </a>
              </div>
              <div className="VidoePoetry-item">
                <img className="poertryimg-img" src={poertryimg4} alt="Logo" />

                <div className="VidoePoetry-item-text">
                  Short, nature-focused poems.
                </div>
                <a href="/VidoePage" className="VidoePoetry-item-btn">
                  <img className="playicon-img" src={playicon} alt="Logo" />
                </a>
              </div>
              <div className="VidoePoetry-item">
                <img className="poertryimg-img" src={poertryimg5} alt="Logo" />

                <div className="VidoePoetry-item-text">
                  14-line structured poems.
                </div>
                <a href="/VidoePage" className="VidoePoetry-item-btn">
                  <img className="playicon-img" src={playicon} alt="Logo" />
                </a>
              </div>
              <div className="VidoePoetry-item">
                <img className="poertryimg-img" src={poertryimg6} alt="Logo" />

                <div className="VidoePoetry-item-text">
                  Poems without rules.
                </div>
                <a href="/VidoePage" className="VidoePoetry-item-btn">
                  <img className="playicon-img" src={playicon} alt="Logo" />
                </a>
              </div>
              <div className="VidoePoetry-item">
                <img className="poertryimg-img" src={poertryimg7} alt="Logo" />

                <div className="VidoePoetry-item-text">
                  Praise-focused poems
                </div>
                <a href="/VidoePage" className="VidoePoetry-item-btn">
                  <img className="playicon-img" src={playicon} alt="Logo" />
                </a>
              </div>
              <div className="VidoePoetry-item">
                <img className="poertryimg-img" src={poertryimg8} alt="Logo" />

                <div className="VidoePoetry-item-text">
                  Song-like, rhythmic poems
                </div>
                <a href="/VidoePage" className="VidoePoetry-item-btn">
                  <img className="playicon-img" src={playicon} alt="Logo" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPage;
