import React, { useState } from "react";
import "../UserList/Userlist.css";
import "./UploadBlog.css";

function BlogUploadForm() {
  const [title, setTitle] = useState("");
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [category, setCategory] = useState("");
  const [writer, setWriter] = useState("");

  // Optional fields
  const [optionalTitle, setOptionalTitle] = useState("");
  const [optionalDescription, setOptionalDescription] = useState("");
  const [optionalImage, setOptionalImage] = useState(null);

  // Error states
  const [errors, setErrors] = useState({
    title: "",
    image: "",
    description: "",
    date: "",
    category: "",
    writer: "",
  });

  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  const handleOptionalImageChange = (event) => {
    setOptionalImage(event.target.files[0]);
  };

  const validateForm = () => {
    let formIsValid = true;
    const newErrors = {
      title: "",
      image: "",
      description: "",
      date: "",
      category: "",
      writer: "",
    };

    if (!title) {
      formIsValid = false;
      newErrors.title = "Title is required";
    }
    if (!image) {
      formIsValid = false;
      newErrors.image = "Image is required";
    }
    if (!description) {
      formIsValid = false;
      newErrors.description = "Description is required";
    }
    if (!date) {
      formIsValid = false;
      newErrors.date = "Date is required";
    }
    if (!category) {
      formIsValid = false;
      newErrors.category = "Category is required";
    }
    if (!writer) {
      formIsValid = false;
      newErrors.writer = "Writer is required";
    }

    setErrors(newErrors);
    return formIsValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      const blogPost = {
        title,
        image,
        description,
        date,
        category,
        writer,
        optionalTitle,
        optionalDescription,
        optionalImage,
      };
      console.log(blogPost);
      // Here you can add code to send the data to an API or backend
      setTitle("");
      setImage(null);
      setDescription("");
      setDate("");
      setCategory("");
      setWriter("");
      setOptionalTitle("");
      setOptionalDescription("");
      setOptionalImage(null);
      setErrors({
        title: "",
        image: "",
        description: "",
        date: "",
        category: "",
        writer: "",
      }); // Clear errors on successful submission
    }
  };

  return (
    <div className="Userlist-main-sec">
      <div className="Userlist-heading container p-0">
        <h2 className="pt-2 pb-2">Blog Upload</h2>
      </div>
      <div className="upload-form-container container">
        <form onSubmit={handleSubmit} className="upload-form row">
          <div className="From-upload col-md-4">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
            {errors.title && (
              <div className="error-message">{errors.title}</div>
            )}
          </div>
          <div className="From-upload col-md-4">
            <label htmlFor="category">Category</label>
            <select
              id="category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
            >
              <option value="">Select a category</option>
              <option value="Technology">Technology</option>
              <option value="Health">Health</option>
              <option value="Lifestyle">Lifestyle</option>
              <option value="Education">Education</option>
              <option value="Business">Business</option>
            </select>
            {errors.category && (
              <div className="error-message">{errors.category}</div>
            )}
          </div>
          <div className="From-upload col-md-4">
            <label htmlFor="writer">Writer</label>
            <select
              id="writer"
              value={writer}
              onChange={(e) => setWriter(e.target.value)}
              required
            >
              <option value="">Select a writer</option>
              <option value="Writer 1">Writer 1</option>
              <option value="Writer 2">Writer 2</option>
              <option value="Writer 3">Writer 3</option>
              <option value="Writer 4">Writer 4</option>
            </select>
            {errors.writer && (
              <div className="error-message">{errors.writer}</div>
            )}
          </div>
          <div className="From-upload col-md-6">
            <label htmlFor="date">Date</label>
            <input
              type="date"
              id="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
            />
            {errors.date && <div className="error-message">{errors.date}</div>}
          </div>
          <div className="From-upload col-md-6">
            <label htmlFor="image">Image</label>
            <input
              type="file"
              id="image"
              onChange={handleImageChange}
              required
            />
            {errors.image && (
              <div className="error-message">{errors.image}</div>
            )}
          </div>
          <div className="From-upload col-md-12">
            <label htmlFor="description">Description</label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            ></textarea>
            {errors.description && (
              <div className="error-message">{errors.description}</div>
            )}
          </div>

          {/* Optional Fields */}
          <div className="From-upload col-md-6">
            <label htmlFor="optionalTitle">Optional Title</label>
            <input
              type="text"
              id="optionalTitle"
              value={optionalTitle}
              onChange={(e) => setOptionalTitle(e.target.value)}
            />
          </div>

          <div className="From-upload col-md-6">
            <label htmlFor="optionalImage">Optional Image</label>
            <input
              type="file"
              id="optionalImage"
              onChange={handleOptionalImageChange}
            />
          </div>
          <div className="From-upload col-md-12">
            <label htmlFor="optionalDescription">Optional Description</label>
            <textarea
              id="optionalDescription"
              value={optionalDescription}
              onChange={(e) => setOptionalDescription(e.target.value)}
            ></textarea>
          </div>
          <div className="btn-group-upload col-12">
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default BlogUploadForm;
