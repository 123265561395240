import React, { useState } from "react";
import About from "../../../src/assets/Brown_Minimalist_Full_Photo_Instagram_Post-removebg-preview.png";
import "../AboutUs/aboutUs.css";
import "../Featured-blog/Featureblog.css";
import "../FamousPoetry/FamousPoetry.css";
import "../Footer/Footer.css";

import instagram from "../../../src/assets/icon/ant-design--instagram-filled.svg";
import youtube from "../../../src/assets/icon/flowbite--youtube-solid.svg";
import facebook from "../../../src/assets/icon/grommet-icons--facebook-option.svg";

const AboutUs = () => {
  return (
    <div className="AboutUs-main">
      <div className="AboutUs-contanier container">
        <div className="AboutUs-main-Section ">
          <div className="row">
            <div className="AboutUs-img col-lg-5 col-md-5 col-sm-12">
              <img src={About} alt="About Us" />
            </div>
            <div className="AboutUs-heading col-lg-7 col-md-7 col-sm-12 ">
              <div className="about-text-container">
                <h2 className="FamousPoetry-heading">
                  Welcome to Mehve <div className="Feature-img">Muhabbat</div>
                </h2>
                <div className="AboutUs-text">
                  <p>
                    Welcome to “Mehve Muhabbat” ! We’re here to share our deep
                    love for Urdu poetry with you. Here, you'll find a
                    collection of iconic pieces of Urdu poetry that speak to the
                    heart and soul. Whether you're a lifelong fan or new to the
                    world of poetry, we hope our words inspire and resonate with
                    you.
                  </p>
                </div>
                <div className="AboutUs-social-media Footer-links-container">
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/share/QaFwDrMtj16kgKyC/?mibextid=qi2Omg">
                        <img src={facebook} alt="Logo" />
                      </a>
                    </li>
                    <li>
                      <a href="https://youtube.com/@mehvemuhabbat?si=_ge5cMNinV5d60kw">
                        <img src={youtube} alt="Logo" />
                      </a>
                    </li>

                    <li>
                      <a href="https://www.instagram.com/mehve_muhabbat?igsh=ZWw4YXNhZnpuMzN5">
                        <img src={instagram} alt="Logo" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
